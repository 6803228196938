import { mapState, mapActions } from 'vuex'
import SCard from '../../../components/ui/s-card'
import SLink from '../../../components/ui/s-link'

export default {
  name: 'MyReports',

  components: {
    SCard,
    SLink,
    ReportCard: () => import('../../../components/ReportCard'),
  },

  data() {
    return {
      records: [],
      isFullyLoaded: false,
      showError: false,
      pagination: null,
    }
  },

  async mounted() {
    await this.getMyReports()
  },

  computed: {
    ...mapState(['loading', 'error', 'company']),

    companyPage() {
      const { slug, slugId } = this.company
      return 'company/' + slug + '/' + slugId
    },
  },

  methods: {
    ...mapActions(['getReports']),

    async getMyReports(page = 1) {
      const { reports, pagination } = await this.getReports(page)
      reports.map((report) => this.records.push(report))
      this.pagination = pagination
      this.isFullyLoaded = pagination.currentPage === pagination.lastPage
    },

    async loadMore() {
      const nextPage = this.pagination.currentPage + 1
      await this.getMyReports(nextPage)
    },

    reload: () => window.location.reload(),
  },

  watch: {
    error() {
      this.showError = !!this.error
    },
  },
}
